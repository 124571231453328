@import '../../../marketplace.css';

:root {
    --top-spacings: 180px;
    --overlay {
        position: absolute;
        z-index: 5;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
    }
}

.root {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #ffffff 5%);

    @apply --overlay;
    top: calc(var(--top-spacings) * -1);

    overflow: hidden;

    @media (--viewportMedium) {
        top: 0;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #ffffff 20%);
    }
}

.container {
    margin-top: var(--top-spacings);
    background: #fff;

    @media (--viewportMedium) {
        margin-top: 280px;
        padding: 0 115px;
    }
}

.heading {
    @apply --fontSizeXL;
    text-align: center;
    font-weight: 700;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        @apply --fontSizeXXXL;
        padding-top: 64px;
    }
}

.cards {
    & li {
        list-style: none;
        margin-bottom: 32px;
    }

    @media (--viewportMedium) {
        display: flex;
        flex-wrap: wrap;
        column-gap: 32px;

        & li {
            flex-basis: calc(50% - 16px);
        }
    }
}

.footerOverlay {
    @apply --overlay;
    top: unset;
    height: 140px;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #ffffff 90%);
    pointer-events: none;
}
