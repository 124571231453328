@import '../../marketplace.css';

.carouselHolder {
    position: relative;

    & ul {
        display: block !important;
        width: 100% !important;
        transform: none !important;
    }

    & li {
        width: 100% !important;
        transition: 0.35s;
    }

    & li[aria-selected='false'] {
        opacity: 0;
        pointer-events: none;
        position: absolute !important;
    }

    @media (--viewportMedium) {
        margin-bottom: 32px;
    }

    &.isSingle {
        @media (--viewportMediumMax) {
            margin-bottom: 32px;
        }
    }
}

.sliderButton {
    @apply --sliderButton;
}

.sliderButtonBack {
    @apply --sliderButtonBack;
}

.sliderButtonNext {
    @apply --sliderButtonNext;
}

.itemSlider {
    background: white;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 24px;

    @media (--viewportMedium) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & h4 {
        display: flex;
        align-items: center;
        column-gap: 16px;

        @apply --fontSizeXL;
        font-weight: 700;
        margin: 0 0 16px 0;

        @media (--viewportSmallMax) {
            flex-direction: column-reverse;
            align-items: flex-start;
            row-gap: 16px;
        }
    }

    & p {
        margin-bottom: 24px;

        @media (--viewportMedium) {
            margin: 0;
        }
    }
}

.sliderActionButton {
    width: auto;
    border-radius: 32px;

    flex-shrink: 0;

    min-height: 28px;
    padding: 7px 16px;

    @apply --fontSizeSM;
    font-weight: 600;

    @media (--viewportMedium) {
        @apply --fontSizeMM;
        font-weight: 600;

        min-height: 52px;
        padding: 11px 17px;
    }
}

.sliderButtonsGroup {
    display: flex;
    justify-content: flex-end;

    @media (--viewportSmallMax) {
        justify-content: space-between;
        position: relative;
        top: -27px;
    }
}

.dotGroup {
    @apply --dotGroup;
}

.creditsBadge {
    @apply --badge-new-item;
    padding: 0 8px;
}

.followers {
    & > div {
        @media (--viewportSmallMax) {
            margin: 0 !important;
        }
    }
}
