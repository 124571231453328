@import '../../marketplace.css';

:root {
    --listingPageContainer {
        max-width: 1128px;
        padding: 0 36px;
    }

    --listingParagraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
    }
}

.pageRoot {
    display: flex;
    padding-bottom: 90px;

    @media (--viewportLarge) {
        padding-bottom: 0;
    }
}

.loadingText {
    margin: 24px;
}

.errorText {
    color: var(--failColor);
    margin: 24px;
}

.listingCards {
    padding: 0 24px 96px 24px;
    display: block;
    position: relative;

    @media (--viewportLarge) {
        padding: 0 36px 96px 36px;
    }
}

.bottomSeperator {
    margin-top: 40px;

    @media (--viewportMedium) {
        margin-top: 80px;
    }
}

.listingPageCardSingle {
    max-width: 100%;
    padding: 0 36px;

    @media (--viewportMedium) {
        max-width: 50%;
    }

    @media (--viewportLarge) {
        max-width: 33%;
    }
}

.inWishlist {
    & svg {
        stroke: rgb(255, 56, 92);

        &:hover {
            stroke: rgb(255, 56, 92);
        }
    }
}

.contentContainer {
    width: 100%;
    position: relative;

    @media (--viewportLarge) {
        max-width: 1150px;
        margin: 0 auto;
        display: flex;
    }
}

.mainSection,
.navigationSection {
    margin: 0 24px;
}
.mainSection {
    @media (--viewportLarge) {
        width: calc(100% - 48px);
    }
}
.navigationSection {
    width: calc(100% - 48px);
    max-width: 1150px;

    margin-top: 24px;

    @media (--viewportLarge) {
        margin-top: 70px;
    }
}

.mainContent {
    display: block;

    margin-bottom: 30px;

    @media (--viewportMedium) {
        margin-top: 24px;
    }

    @media (--viewportLarge) {
        margin-top: 24px;
        margin-bottom: 0px;
        display: flex;

        width: 100%;
    }
}

.verticalDistance {
    margin-bottom: 30px;
}

.participantText {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
}

.bookingPanel {
    width: 100%;
    padding: 18px 24px 18px 16px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    transform: translateZ(0);
    text-align: left;

    @media (--viewportMedium) {
        padding: 18px 50px;
        background-size: 17%;
    }

    @media (--viewportLarge) {
        background-size: 100%;
        display: block;
        background-color: none;
        border-radius: 4px;
        box-shadow: none;
        z-index: 1;
        padding: 48px 50px 50px;
        /*padding       : 50px;*/
        /*padding-top   : 30px;*/
        flex-shrink: 0;
        align-self: flex-start;
    }
}

.heading {
    /* margin-bottom: 33px; */
    padding: 0 24px;

    @media (--viewportMedium) {
        margin-bottom: 0;
        padding: 0;
    }
}

.title {
    /* Font */
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    line-height: 36px;
    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 18px;

    @media (--viewportMedium) {
        margin-top: 0;
        line-height: 46px;
    }
}

.contactWrapper {
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.separator {
    margin: 0 6px;
}

.contactLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH4FontStyles;
    margin: 0;
}

.sectionDescription {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 52px;
    }
}

.descriptionTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

.description {
    margin-top: 0;
    margin-bottom: 0;

    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.sectionMap {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 0;
    }
}

.sectionHost {
    position: relative;
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.map {
    /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
    height: 50vh;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    /* Static map   : dimensions are 640px */
    max-width: 640px;
    max-height: 640px;
    background-color: #eee;
    padding: 0;
    border: 0;
    cursor: pointer;

    @media (--viewportMedium) {
        margin-bottom: 10px;
        height: 75vh;
    }

    @media (--viewportLarge) {
        height: 417px;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.titleShiftedLeft {
    margin-left: 15px;
    margin-bottom: 30px;
}

.listingSecondaryText {
    @apply --marketplaceH4FontStyles;
    color: var(--matterColorDark);
    font-weight: 600;
    margin: 0;
}

.shareModalContainer {
    margin: 0;
    background-color: white;
    border-radius: 8px;
    width: 100%;

    @media (--viewportMedium) {
        margin: 144px auto auto auto;
        max-width: 480px;
    }
}
.shareModalContainer > button {
    position: unset;
    margin-left: auto;
    padding: 0;
    display: flex;
    align-items: center;

    color: #1a2b49;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.25px;
}

.modalHeader {
    margin: 0 0 16px 0;
    @apply --fontSizeXL;
    font-weight: 700;

    @media (--viewportMedium) {
        @apply --fontSizeXXL;
    }
}

.modalSubHeader {
    margin-bottom: 32px;
    color: #1a2b49;
}

.modalContainer {
    flex-basis: 480px;
}

.externalListingModalHolder {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    @media (--viewportMedium) {
        display: block;
    }

    & > svg {
        fill: none;
        margin-top: 40px;

        @media (--viewportMedium) {
            margin-top: 18px;
        }
    }
}

.externalListingModalHeading {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 24px 0 16px 0;
    letter-spacing: -1px;
    padding-right: 10px;
}

.externalListingModalNotification {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    margin: 0;
}

.externalListingModalSource {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin: auto 0;

    @media (--viewportMedium) {
        margin: 86px 0;
    }
}

.externalListingModalBtn {
    @apply --marketplaceModalBtnMainStyle;

    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    max-height: 59px;

    &:hover {
        text-decoration: none;
    }
}

.disciplineInfoItemsContainer {
    margin-top: 3px;
    position: static;

    @media (--viewportMedium) {
        position: absolute;
    }
}

.infoItemWithNoHeight {
    position: absolute;
}

.infoItemWithStaticHeight {
    position: static;
}

.externalModalContainer {
    flex-basis: 480px !important;
    padding: 24px 24px 0 24px !important;

    @media (--viewportMedium) {
        border-top: 6px solid var(--marketplaceColor) !important;
        padding: 40px 44px 40px 44px !important;
    }
}

.externalModalContainer {
    padding: 24px !important;

    @media (--viewportMedium) {
        padding: 40px !important;
    }
}

.fieldItem {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #4a4a4a;
    margin-bottom: 21px;
}

.externalModalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* NL: from here is the css for the items we added */
/* this is a very static css that needs to be changed */

.noRatingsYetContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 90%;
    margin: auto;
    padding: 2em 0;

    @media (--viewportSmallMax) {
        width: 100%;
        padding: 1em 0;
    }
}

.reviewsButton {
    @apply --marketplaceModalBtnMainStyle;
    width: 80%;
    margin: auto;
    padding: 1em 0;
}

.reviewsButtonDisabled {
    @apply --marketplaceButtonDisabledStyles;
    border: none;
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    @media (--viewportLarge) {
        align-items: center;
    }
}

.leftSectionWrapper {
    @media (--viewportLarge) {
        display: block;
        margin-right: 24px;
        width: 360px;
        flex-shrink: 0;
    }
}

.rightSectionWrapper {
    @media (--viewportLarge) {
        max-width: 766px;
        width: 100%;
    }
}

.mobileNavPanel {
    display: flex;
    align-items: center;

    & button {
        margin: 0;
        & span {
            display: none;
        }
    }
}
.mobileNavPanelRoot {
    display: flex;
    justify-content: space-between;

    padding: 18px 16px;

    box-shadow: 0px 4px 6px -1px #0000000d;
    background: white;
}

.backButton {
    width: auto;
    min-height: unset;

    @apply --fontSizeSM;

    background: none;
    color: #1a2b49;
    font-weight: 400;

    &:hover {
        background: none;
    }

    & svg {
        transform: rotate(180deg);
        margin-right: 8px;
    }

    & path {
        fill: #1a2b49;
        stroke: #1a2b49;
    }
}

.burgerMenu {
    margin: 0;
    padding: 0;
    & > svg {
        margin: 0;
        /* notification dot */
        & + code {
            top: 0;
        }
    }
}

.desktop {
    display: none;

    @media (--viewportLarge) {
        display: block;
    }
}
.mob {
    display: block;

    @media (--viewportLarge) {
        display: none;
    }
}

.reportingMobileSection {
    margin-bottom: 24px;
}

.pageContent {
    padding: 0 24px;
}

.noResultsContainer {
    @media (--viewportSmallMax) {
        margin-top: 24px;
        padding: 24px;
    }
}

.noResultsBlock {
    max-width: 1150px;
    margin: 81px auto 0 auto;
}

.overlayed {
    max-height: 1760px; /* 1895px; */
    overflow: hidden;
    margin-bottom: 0;

    @media (--viewportMediumMax) {
        max-height: 1600px;
    }

    @media (--viewportSmallMax) {
        max-height: 2950px;
    }
}
