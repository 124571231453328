@import '../../../marketplace.css';

:root {
    --parapraph-bold {
        @apply --fontSizeMM;
        font-weight: 600;
    }
}

.packagesSection {
    @apply --flex-centered;

    flex-direction: column;

    @media (--viewportSmallMax) {
        margin-bottom: 147px;
    }
}

.packagesWrapper {
    display: flex;
    flex-direction: column;

    position: relative;

    width: 100%;

    margin-bottom: 32px;
    padding: 20px 16px 16px 16px;

    border-radius: 8px;
    background: #fff;
    border: 1px solid var(--Ground-Ground-010, #e6e6e6);
}

.iconHolder {
    position: absolute;
    top: -20px;
    left: calc(50% - 20px);

    z-index: 1;

    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--Ground-Ground-010, #e6e6e6);

    @apply --flex-centered;

    & path {
        fill: var(--marketplaceColor);
    }
}

.packagesCategoryHeading {
    width: 100%;
    margin-bottom: 36px;

    color: var(--marketplaceColor);
    font-weight: 600;

    @media (--viewportSmallMax) {
        @apply --parapraph-bold;
    }
}

.packagesTitle {
    @apply --parapraph-bold;
    margin: 0;

    @media (--viewportSmallMax) {
        @apply --parapraph-bold;
    }
}

.packagesPrice,
.packagesSubscriptionNote {
    @apply --fontSizeSM;
}

.packagesPrice {
    color: var(--marketplaceColor);
    font-weight: 600;
}

.packagesSubscriptionNote {
    color: #b2b2b2;
}

.packagesUpperSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.packagesPricingInfo {
    display: flex;
}

.packagesDescription {
    @apply --fontSizeXS;

    @media (--viewportSmallMax) {
        & br {
            display: none;
        }
    }
}

.moreInfoLink {
    color: var(--marketplaceColor);

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.packagesFooterWrapper {
    display: flex;
    align-items: center;

    & svg {
        margin-right: 8px;
        color: transparent;
    }

    & span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}

.packagesFooterHeading {
    font-weight: 700;
    font-size: 14px;
    line-height: 8px;
    margin: 0;

    & span {
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

.proceedWithNoPackageButton {
    width: 100%;

    margin: 12px 0 96px 0;
}

.proceedWithPackageButton {
    &:last-of-type {
        width: 100%;
        margin: 12px 0 96px 0;
    }
}

.proceedWithFooter {
    width: 100%;

    @media (--viewportSmallMax) {
        background: white;
        border-top: 1px solid #e6e6e6;

        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;

        padding: 12px 24px;
        width: auto;

        z-index: 1;

        & .proceedWithPackageButton,
        & .proceedWithNoPackageButton {
            margin: 0;
        }

        & > button:nth-of-type(2) {
            margin-top: 16px;
        }
    }
}

.badges {
    display: flex;

    & > div {
        @apply --badge-new-item;
        display: inline-flex;
        color: #1e9f55;
        background-color: #f0fff6;
        padding: 2px 8px;

        &:last-of-type {
            color: var(--marketplaceColor);
            background-color: #f9f4fa;
            margin-left: auto;
        }
    }
}
