@import '../../marketplace.css';

/* Content is visible as modal layer */
.isOpen {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    overflow: auto;

    /* scrollLayer is the .root for the Modal */
    & .scrollLayer {
        @apply --marketplaceModalRootStyles;
        @apply --scrollBarStyled;

        /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
        background-color: var(--matterColorLight);

        /* Additional styles for the modal window, dimming the background and positioning the modal */
        min-height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        @media (--viewportMedium) {
            padding: 0;
            background-color: rgba(0, 0, 0, 0.4);
            background-image: none;
        }
    }
}

.container {
    @apply --marketplaceModalBaseStyles;
    @apply --scrollBarStyled;
    min-height: 100vh;
    height: 100%;
    padding: 0;

    @media (--viewportSmallMax) {
        margin: 0 auto;
        /*height: fit-content;*/
        overflow-y: scroll;
    }

    @media (--viewportMedium) {
        flex-basis: 480px;
        min-height: auto;
        height: auto;
    }
}

.containerSticky {
    @apply --modalWithStickyFooter;
}

.content {
    @apply --scrollBarStyled;
    padding: 16px 24px 24px 24px;
}

.contentSticky {
    padding: 16px 24px 0 24px;
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
    display: none;
}

.close {
    @apply --marketplaceModalCloseStyles;
    padding: 0;
    cursor: auto;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    position: relative;

    @media (--viewportSmallMax) {
        width: 100%;
        background: white;
    }
}

.closeText {
    @apply --marketplaceModalCloseText;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    /* Sizing (touch area) */
    padding: 16px;
    margin: 0;

    &.noCloseText {
        padding: 20px;
        border-radius: 50%;

        @media (--viewportSmallMax) {
            padding: 12px;
        }
    }
}

.closeIcon {
    @apply --marketplaceModalCloseIcon;
    margin-left: 0;
}

.closeLight {
    color: white;
    transition: var(--transitionStyleButton);

    & svg {
        & path {
            stroke: white;
            fill: white;
        }
    }
}

.scrolled {
    border-bottom: 1px solid #e6e6e6;
}

.stickyFooter {
    @apply --modalStickyFooter;
}
