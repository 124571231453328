@import '../../../marketplace.css';

.modalContent {
    @media (--viewportSmallMax) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    & .title {
        margin: 0 0 16px 0;
    }

    & .description {
        margin: 0 0 32px 0;
    }

    & > button[type='button'] {
        margin-top: auto;

        @media (--viewportMedium) {
            margin-top: 40px;
        }
    }
}

.benefitItem {
    display: flex;
    column-gap: 8px;

    & p {
        margin: 0;
    }

    & svg {
        flex-shrink: 0;
    }

    & path {
        fill: var(--marketplaceColor);
    }
}

.benefitsHolder {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
